import React, { useState } from 'react';
import { TextField, Switch, FormControlLabel, Grid, Button, Typography, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModelConfiguration, ConfigurationField } from '../../../../models/OrganizationModel';
import AddFieldModal from './AddConfigFieldModal/AddConfigFieldModal';
import { PrimarySwitch } from '../../../../components/Switch/PrimarySwitch';

interface ConfigFormProps {
  config: ModelConfiguration;
  onChangeConfig: (formData: ModelConfiguration) => void;
}

interface RenderInputFieldProps {
  fieldKey: string;
  fieldConfig: ConfigurationField;
  value: any;
  onChange: (fieldKey: string, newValue: any) => void;
}

const RenderInputField: React.FC<RenderInputFieldProps> = ({ fieldKey, fieldConfig, value, onChange }) => {
  switch (fieldConfig.type) {
    case 'text':
      return (
        <Grid item xs={12}>
          <TextField
            key={fieldKey}
            label={fieldKey}
            value={value as string}
            onChange={e => onChange(fieldKey, e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      );
    case 'integer':
    case 'decimal':
      return (
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            key={fieldKey}
            label={fieldKey}
            value={value ? String(value) : ''}
            onChange={e => onChange(fieldKey, parseFloat(e.target.value))}
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
          />
        </Grid>
      );
    case 'boolean':
      return (
        <Grid item xs={6} sm={4} md={3}>
          <FormControlLabel
            key={fieldKey}
            control={<PrimarySwitch checked={value as boolean} onChange={e => onChange(fieldKey, e.target.checked)} />}
            label={fieldKey}
          />
        </Grid>
      );
    default:
      return null;
  }
};

const ConfigForm: React.FC<ConfigFormProps> = ({ config, onChangeConfig }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true); // State to manage the collapse

  const handleInputChange = (fieldKey: string, newValue: any) => {
    const newConfig: any = { ...config, [fieldKey]: { type: config[fieldKey]?.type, value: newValue } };
    onChangeConfig(newConfig);
  };

  const handleAddInputField = (fieldKey: string, fieldConfig: ConfigurationField) => {
    const newConfig: any = { ...config, [fieldKey]: fieldConfig };
    onChangeConfig(newConfig);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(prev => !prev); // Toggle the collapse
  };

  const sortedFieldKeys = Object.keys(config).sort((a, b) => {
    const typeOrder = ['boolean', 'integer', 'decimal', 'text'];
    const typeA = typeOrder.indexOf(config[a]?.type || '');
    const typeB = typeOrder.indexOf(config[b]?.type || '');
    if (typeA === typeB) {
      return a.localeCompare(b);
    }
    return typeA - typeB;
  });

  // Separate the fields by type
  const fieldsByType = sortedFieldKeys.reduce<Record<string, string[]>>((acc, key) => {
    const type = config[key]?.type;
    if (type) {
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(key);
    }
    return acc;
  }, {});

  return (
    <>
      <Grid container justifyContent="flex-start" alignItems="center" marginBottom={2} marginTop={2}>
        <Typography variant="h6">Config</Typography>
        <IconButton onClick={handleToggleExpand} aria-expanded={isExpanded} aria-label="show config fields">
          <ExpandMoreIcon
            style={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }}
          />
        </IconButton>
      </Grid>
      <Collapse in={isExpanded} sx={{ marginBottom: '12px' }}>
        {['boolean', 'integer', 'decimal', 'text'].map(type => (
          <Grid container spacing={2} key={type}>
            {fieldsByType[type]?.map(
              fieldKey =>
                !!config[fieldKey] && (
                  <RenderInputField
                    key={fieldKey}
                    fieldKey={fieldKey}
                    fieldConfig={config[fieldKey] as ConfigurationField}
                    value={config[fieldKey]?.value}
                    onChange={handleInputChange}
                  />
                )
            )}
          </Grid>
        ))}
        <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ marginTop: '10px' }}>
          Add New Field
        </Button>
      </Collapse>
      <AddFieldModal open={isModalOpen} onClose={handleCloseModal} onAddField={handleAddInputField} />
    </>
  );
};

export default ConfigForm;
