import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RadioButtonUncheckedRounded, TripOrigin } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from '@mui/material';

import { COLOR_PRIMARY } from '../../../../constants/colors';

import Flex from '../../../../components/utils/flex/Flex';
import { Headline3 } from '../../../../components/typography/Headlines';
import { useStore } from '../../../../hooks/useStore';
import { PrimarySwitch } from '../../../../components/Switch/PrimarySwitch';
import Button from '../../../../components/buttons/Button';
import TextInput from '../../../../components/inputs/TextInput';
import UploadLogo from './UploadLogo';
import FlexColumnStart from '../../../../components/utils/flex/FlexColumnStart';
import ConfigForm from './RenderConfigFields';
import { ModelConfiguration } from '../../../../models/OrganizationModel';

type EditModelProps = {
  onClose: () => void;
  openAddDefaultModelModal: (headerTextValue: string) => void;
};

const EditModel = ({ openAddDefaultModelModal, onClose }: EditModelProps) => {
  const {
    modelStore: {
      currentOrganizationModel,
      updateOrganizationModel,
      defaultOrganizationModel,
      defaultRagOrganizationModel,
    },
    localizationStore: { i18next: i18n },
  } = useStore();

  const isDefaultModel = defaultOrganizationModel.id === currentOrganizationModel.id;
  const isDefaultModelRag = defaultRagOrganizationModel.id === currentOrganizationModel.id;

  const [tempOrganizationModel, setTempOrganizationModel] = useState(currentOrganizationModel);
  const [isDirtyState, setIsDirtyState] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log('currentOrganizationModel ------------------->> ', currentOrganizationModel);

  const { displayName, isActive, description, isDefault, isDefaultFileModel, config } = tempOrganizationModel;
  console.log('config UPTOP ------------------->> ', config);

  const handleIsActiveChange = (isActive: boolean) => {
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        isActive,
      };
    });

    setIsDirtyState(true);
  };

  const logoPictureChange = (value: { fileKey: string; signedUrl: string; expirationDate: Date }) => {
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        logoPicture: value.signedUrl,
        logoPictureFileKey: value.fileKey,
        logoPictureUrlExpiration: value.expirationDate,
      };
    });
    setIsDirtyState(true);
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setIsDirtyState(true);
    console.log('name, value ------------------->> ', name, value);

    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        [name]: value,
      };
    });
  };

  const handleSaveChanges = async () => {
    setLoading(true);

    if (isDefaultModel && (!tempOrganizationModel.isDefault || !isActive)) {
      if (!isActive) {
        setTempOrganizationModel(currentValue => {
          return {
            ...currentValue,
            isDefault: false,
          };
        });
      }

      openAddDefaultModelModal('modelsPage.modal.header.needAtLeastOneDefaultModel');
      setLoading(false);
      return;
    }

    console.log('tempOrganizationModel-->', tempOrganizationModel);

    if (!isDefault && tempOrganizationModel.isDefault) {
      await updateOrganizationModel({ ...defaultOrganizationModel, isDefault: false });
    }

    await updateOrganizationModel(tempOrganizationModel);
    setLoading(false);
    onClose();
  };

  const handleIsMarkedAsDefaultChange = async (isMarkedAsDefault: boolean) => {
    setIsDirtyState(true);
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        isDefault: isMarkedAsDefault,
      };
    });
  };

  const handleIsMarkedAsDefaultRagChange = async (isMarkedAsDefault: boolean) => {
    setIsDirtyState(true);
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        isDefaultFileModel: isMarkedAsDefault,
      };
    });
  };

  const temperatureValues = [
    {
      value: 0.5,
      label: '0.5',
    },
    {
      value: 0.6,
      label: '0.6',
    },
    {
      value: 0.7,
      label: '0.7',
    },
    {
      value: 0.8,
      label: '0.8',
    },
    {
      value: 0.9,
      label: '0.9',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 1.5,
      label: '1.5',
    },
  ];

  const maxTokenValues = [
    {
      value: 1024,
      label: '1024',
    },
    {
      value: 2048,
      label: '2048',
    },
    {
      value: 4096,
      label: '4096',
    },
    {
      value: 8192,
      label: '8192',
    },
    {
      value: 16384,
      label: '16384',
    },
  ];

  const maxTokenValuesModel = [
    {
      modelVersion: 'gpt-4-32k',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-4-32k',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-4-32k',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'gpt-4-32k',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'gpt-4-32k',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'gpt-4-32k',
      value: 32768,
      label: '32768',
    },
    {
      modelVersion: 'amazon.titan-text-express-v1',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'amazon.titan-text-express-v1',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'amazon.titan-text-express-v1',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'amazon.titan-text-express-v1',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'anthropic.claude-v1',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'anthropic.claude-v1',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'anthropic.claude-v1',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'anthropic.claude-v1',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'anthropic.claude-v1',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'anthropic.claude-v1',
      value: 32768,
      label: '32768',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 32768,
      label: '32768',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 65536,
      label: '65536',
    },
    {
      modelVersion: 'anthropic.claude-v2',
      value: 100000,
      label: '100000',
    },
    {
      modelVersion: 'command',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'command',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'command',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'command-light',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'command-light',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'command-light',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'command-light-nightly',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'command-light-nightly',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'command-light-nightly',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'command-light-nightly',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'command-nightly',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'command-nightly',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'command-nightly',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'command-nightly',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'gpt-3.5-turbo',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-3.5-turbo',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-3.5-turbo',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'gpt-35-turbo',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-35-turbo',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-35-turbo',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'gpt-3.5-turbo-16k',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-3.5-turbo-16k',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-3.5-turbo-16k',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'gpt-3.5-turbo-16k',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'gpt-3.5-turbo-16k',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'gpt-4',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-4',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-4',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'gpt-4-file',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-4-file',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-4-file',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'meta.llama2-70b-chat-v1',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'meta.llama2-70b-chat-v1',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'meta.llama2-70b-chat-v1',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'meta.llama2-70b-chat-v1',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'meta.llama2-70b-chat-v1',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'sageMaker-llama2-file',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'sageMaker-llama2-file',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'sageMaker-llama2-file',
      value: 4096,
      label: '4096',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 8192,
      label: '8192',
    },
    {
      modelVersion: 'sageMaker-llama2',
      value: 16384,
      label: '16384',
    },
    {
      modelVersion: 'stability.stable-diffusion-xl-v0',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'stability.stable-diffusion-xl-v0',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-4-turbo',
      value: 1024,
      label: '1024',
    },
    {
      modelVersion: 'gpt-4-turbo',
      value: 2048,
      label: '2048',
    },
    {
      modelVersion: 'gpt-4-turbo',
      value: 4096,
      label: '4096',
    },
  ];

  const onChangeModelConfig = (config: ModelConfiguration) => {
    if (config) {
      setIsDirtyState(true);
      setTempOrganizationModel(prevState => {
        return { ...prevState, config };
      });
    }
  };

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex>
        <Flex sx={{ alignItems: 'center', flex: 1 }}>
          <Headline3>{currentOrganizationModel.displayName}</Headline3>
          <PrimarySwitch checked={isActive} onChange={event => handleIsActiveChange(event.target.checked)} />
        </Flex>
        <Box>
          <Button disabled={!isDirtyState} onClick={handleSaveChanges} loading={loading}>
            {i18n.t('common.saveChanges.action')}
          </Button>
        </Box>
      </Flex>

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '24px' }}>
        {i18n.t('models.textField.displayName.label')}
      </Typography>

      <TextInput
        id="displayName"
        placeholder={i18n.t('models.textField.displayName.placeHolder')}
        name={'displayName'}
        value={displayName}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        sx={{
          marginBottom: '16px',
          '& > .MuiInputBase-root': { height: '48px !important' },
          '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
          '& .MuiInputLabel-root': { top: '-4px' },
          '& .MuiInputLabel-shrink': { top: 0 },
        }}
      />

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '16px' }}>
        {i18n.t('models.textField.description.label')}
      </Typography>

      <TextInput
        id="description"
        placeholder={i18n.t('models.textField.description.placeHolder')}
        name={'description'}
        value={description}
        // error={i18n.t(formErrors.title)}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        rows={4}
      />

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '100px' }}>
        {i18n.t('models.textField.sourceLogo.label')}
      </Typography>

      <UploadLogo setLogoPicture={logoPictureChange} />

      <ConfigForm config={config} onChangeConfig={onChangeModelConfig} />

      <FormControl sx={{ marginTop: 'auto' }}>
        <FormControlLabel
          onChange={(_event, checked) => {
            handleIsMarkedAsDefaultChange(checked);
          }}
          control={<Checkbox checked={isDefault} icon={<RadioButtonUncheckedRounded />} checkedIcon={<TripOrigin />} />}
          sx={isDefaultModel ? { '& > *': { color: `${COLOR_PRIMARY} !important` } } : {}}
          label={i18n.t('models.radioButton.defaultModel')}
        />
      </FormControl>

      {(currentOrganizationModel.modelVersion.indexOf('file') !== -1 ||
        currentOrganizationModel.modelVersion.indexOf('agent') !== -1) && (
        <FormControl sx={{ marginTop: 'auto' }}>
          <FormControlLabel
            onChange={(_event, checked) => {
              handleIsMarkedAsDefaultRagChange(checked);
            }}
            control={
              <Checkbox
                checked={isDefaultFileModel}
                icon={<RadioButtonUncheckedRounded />}
                checkedIcon={<TripOrigin />}
              />
            }
            sx={isDefaultModelRag ? { '& > *': { color: `${COLOR_PRIMARY} !important` } } : {}}
            label={i18n.t('models.radioButton.defaultModelRag')}
          />
        </FormControl>
      )}
    </Flex>
  );
};

export default observer(EditModel);
