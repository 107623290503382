import React, { useState, useEffect } from 'react';
import { Drawer as MuiDrawer, List, ListSubheader, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useStore } from '../../hooks/useStore';
import { Paths } from '../../constants/routes';
import NavItems from './NavItems';
import AVMLogo from '../../config/addvaluemachine/AVMLogo.svg';
import dashboardLogo from '../../config/addvaluemachine/dashboardLogo.svg';
import teamsLogo from '../../config/addvaluemachine/teamsLogo.svg';
import promptLogo from '../../config/addvaluemachine/promptLogo.svg';
import knowledgeBaseLogo from '../../config/addvaluemachine/knowledgeBaseLogo.svg';
import modelsLogo from '../../config/addvaluemachine/modelsLogo.svg';
import integrationLogo from '../../config/addvaluemachine/integrationLogo.svg';
import playgroundLogo from '../../config/addvaluemachine/playgroundLogo.svg';
import modelevaluationLogo from '../../config/addvaluemachine/modelevaluationLogo.svg';
import securityPoliciesLogo from '../../config/addvaluemachine/securityPoliciesLogo.svg';
import warningLogo from '../../config/addvaluemachine/warningLogo.svg';
import auditLogo from '../../config/addvaluemachine/auditLogo.svg';
import logoutLogo from '../../config/addvaluemachine/logoutLogo.svg';
import helpCenterLogo from '../../config/addvaluemachine/helpCenterLogo.svg';
import settingLogo from '../../config/addvaluemachine/settingsLogo.svg';

const drawerWidth = 250;

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : theme.spacing(7) + 1,
    background: '#131F4D',
    color: 'white',
    maxHeight: '100vh',
    position: 'fixed',
    transition: 'height 0.3s ease',
    overflowY: 'visible',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

const useStyles = makeStyles({
  logoImage: {
    maxWidth: '40%',
    paddingLeft: '20px',
    paddingTop: '20px',
    marginBottom: '3rem',
  },
  subheader: {
    background: '#131F4D !important',
    color: 'white !important',
  },
});

interface Tab {
  title: string;
  path: string;
  logo: string;
  subheader?: string;
}

interface DrawerComponentProps {
  isDrawerOpen: boolean;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({ isDrawerOpen }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const {
    authStore: { logout },
    localizationStore: { i18next: i18n },
  } = useStore();

  const tabs: Tab[] = [
    { title: 'Dashboard', path: `${Paths.ADMIN_PORTAL}`, logo: dashboardLogo },
    { title: `${i18n.t('profile.teams.message')}`, path: `${Paths.ADMIN_PORTAL}/teams`, logo: teamsLogo },
    // ! recheck this both routes once again
    { title: `${i18n.t('adminPortal.tabs.users.action')}`, path: `${Paths.ADMIN_PORTAL}/users`, logo: promptLogo },
    // !
    { title: `${i18n.t('adminPortal.tabs.models.action')}`, path: `${Paths.ADMIN_PORTAL}/models`, logo: modelsLogo },
    { title: `${i18n.t('adminPortal.tabs.models.action')}`, path: `${Paths.ADMIN_PORTAL}/models`, logo: modelsLogo },
    // { title: 'Playground', path: `${Paths.ADMIN_PORTAL}/playground`, logo: playgroundLogo },
    { title: 'Model Evaluation', path: `${Paths.ADMIN_PORTAL}/model-evaluation`, logo: modelevaluationLogo },
    // { title: 'Security Policies', path: `${Paths.ADMIN_PORTAL}/security-policies`, logo: securityPoliciesLogo },
    // { title: 'Warnings', path: `${Paths.ADMIN_PORTAL}/warnings`, logo: warningLogo },
    {
      title: `${i18n.t('adminPortal.tabs.integrations.action')}`,
      path: `${Paths.ADMIN_PORTAL}/integrations`,
      logo: integrationLogo,
    },
    {
      title: `${i18n.t('adminPortal.tabs.compliance.action')}`,
      path: `${Paths.ADMIN_PORTAL}/compliance`,
      logo: knowledgeBaseLogo,
    },
    { title: `${i18n.t('profile.governance.message')}`, path: `${Paths.ADMIN_PORTAL}/governance`, logo: teamsLogo },
    { title: `${i18n.t('adminPortal.tabs.logs.action')}`, path: `${Paths.ADMIN_PORTAL}/audit-log`, logo: auditLogo },
    {
      title: `${i18n.t('adminPortal.tabs.setting.action')}`,
      path: `${Paths.ADMIN_PORTAL}/workspace`,
      logo: settingLogo,
    },
    { title: `${i18n.t('appHeader.logout.action')}`, path: '', logo: logoutLogo },
    // { title: 'Help Center', path: `${Paths.ADMIN_PORTAL}/help-center`, logo: helpCenterLogo },
  ];

  useEffect(() => {
    const isLocalStorageAvailable = localStorage.getItem('selectedItem') !== null;
    const storedItem = isLocalStorageAvailable ? localStorage.getItem('selectedItem') : 'Dashboard';

    const matchingTab = tabs.find(tab => location.pathname.startsWith(tab.path) && tab.title !== 'Dashboard');

    const newSelectedItem =
      storedItem && tabs.some(tab => tab.title === storedItem)
        ? storedItem
        : matchingTab
          ? matchingTab.title
          : 'Dashboard';

    setSelectedItem(newSelectedItem);
    if (newSelectedItem !== storedItem) {
      localStorage.setItem('selectedItem', newSelectedItem);
    }
  }, [location.pathname, tabs]);

  const handleLogout = async () => {
    await logout();
    navigate(Paths.LOGIN);
  };

  const handleItemClick = (title: string) => {
    if (title === `${i18n.t('appHeader.logout.action')}`) {
      handleLogout();
    } else {
      setSelectedItem(title);
      localStorage.setItem('selectedItem', title);
    }
  };

  const renderNavItems = (start: number, end: number, subheader?: string) => {
    return (
      <div
        style={{
          marginTop: 'auto',
        }}
      >
        <List subheader={isDrawerOpen && <ListSubheader className={classes.subheader}>{subheader}</ListSubheader>}>
          {tabs.slice(start, end).map((tab, index) => (
            <NavItems
              key={index}
              text={tab}
              isSelected={selectedItem === tab.title}
              isDrawerOpen={isDrawerOpen}
              handleItemClick={handleItemClick}
            />
          ))}
        </List>
      </div>
    );
  };

  return (
    <>
      <Drawer variant="permanent" open={isDrawerOpen}>
        <DrawerHeader sx={{ justifyContent: isDrawerOpen ? 'initial' : '' }}>
          <img src={AVMLogo} alt="AVM Logo" className={isDrawerOpen ? classes.logoImage : ''} />
        </DrawerHeader>
        <div>{renderNavItems(0, 4)}</div>
        {renderNavItems(4, 7, 'MODEL MANAGEMENT')}
        {renderNavItems(7, 11, 'SECURITY')}
        <div style={{ marginTop: 'auto' }}>{renderNavItems(11, tabs.length)}</div>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
